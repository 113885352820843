import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import { playIcon, play, player, playerWrapper, otherContent } from './video-player.module.scss';
import PlayIcon from '../../assets/images/svg/icon-play.svg';

const VideoPlayer = ({
    videoUrl,
    children,
    playNext,
    autoplay,
    coverUrl,
    watchedMark,
    onWatched,
    followProgress,
    wrapperClassName,
    onStart,
    onReady,
    loop,
    remainingReps,
}) => {
    const videoPlayerRef = useRef(null);

    const [playing, setPlaying] = useState(autoplay);
    const [duration, setDuration] = useState(null);
    const [playedSeconds, setPlayedSeconds] = useState([]);
    const [onWatchedDone, setOnWatchedDone] = useState(false);
    const [reps, setReps] = useState(remainingReps);
    const [isPlayerReady, setIsPlayerReady] = useState(false);

    const getPlayIcon = () => (
        <div className={play}>
            <PlayIcon className={playIcon} />
        </div>
    );

    const getPropsWhenAutoplayOff = () => ({
        light: coverUrl,
        onClickPreview: () => setPlaying(true),
        playIcon: getPlayIcon(),
    });

    const getPropsWhenFollowProgressOn = () => ({
        onProgress: handleProgress,
        onDuration: handleDuration,
    });

    const handleEnded = () => {
        if (followProgress) {
            setDuration(null);
            setPlayedSeconds([]);
            setOnWatchedDone(false);
        }
        playNext();
    };

    const handleDuration = (value) => {
        setDuration(value);
    };

    const handleProgress = (progressData) => {
        const second = Math.ceil(progressData.playedSeconds);
        if (second > 0 && !playedSeconds.includes(second)) {
            setPlayedSeconds([...playedSeconds, second]);
        }
    };

    const playerReady = () => {
        const player = checkPlayer();
        if (player !== null) {
            setIsPlayerReady(true);
        }
        if (typeof onReady === 'function') {
            onReady(videoPlayerRef.current);
        }
    };

    const subtractRepsCount = (point, lastPointId) => {
        setTimeout(() => {
            if (lastPointId === point.id) {
                setReps((prevState) => prevState - 1);
            }
        }, 500);
    };

    const addLoopEndPoint = (duration, reps) => {
        const player = checkPlayer();
        if (player !== null) {
            player
                .getCuePoints()
                .then((cuePoints) => {
                    return Promise.allSettled(
                        cuePoints.map((point) => player.removeCuePoint(point.id))
                    );
                })
                .then(() => {
                    return player.addCuePoint(duration - 0.5, {
                        remainingReps: reps,
                    });
                })
                .then((cuePointAdded) => {
                    player.on(
                        'cuepoint',
                        (cuepoint) => subtractRepsCount(cuepoint, cuePointAdded),
                        { once: true }
                    );
                })
                .catch((error) => {
                    switch (error.name) {
                        case 'UnsupportedError':
                            // eslint-disable-next-line no-console
                            console.log(
                                "Cue points aren't supported by the current player or browser"
                            );
                            break;
                        case 'RangeError':
                            // eslint-disable-next-line no-console
                            console.log(
                                "The time is less than 0 or greater than the video's duration"
                            );
                            break;
                        default:
                            // eslint-disable-next-line no-console
                            console.log('Some other error occurred');
                            break;
                    }
                });
        }
    };

    const checkPlayer = () => {
        return (videoPlayerRef.current && videoPlayerRef.current.getInternalPlayer()) || null;
    };

    useEffect(() => {
        if (!followProgress || !duration || onWatchedDone) return;
        if (playedSeconds.length / duration > watchedMark) {
            onWatched();
            setOnWatchedDone(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration, playedSeconds]);

    useEffect(() => {
        if (isPlayerReady) {
            const player = checkPlayer();

            const duration = player.getDuration();

            /*
             * player.getDuration() should return number or null but when the provider is vimeo it returns Promise
             */
            if (typeof duration === 'number') {
                setDuration(player.getDuration());
            } else if (!duration) {
                duration.then((currentVideoDuration) => {
                    setDuration(currentVideoDuration);
                });
            } else {
                setDuration(null);
            }
        }

        if (isPlayerReady && duration > 0 && reps > 0) {
            addLoopEndPoint(duration, reps);
        }

        if (reps === 0) {
            playNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reps, duration, isPlayerReady]);

    useEffect(() => {
        setReps(remainingReps);
    }, [remainingReps]);

    return (
        <div className={`${playerWrapper} ${wrapperClassName}`}>
            {videoUrl ? (
                <ReactPlayer
                    ref={videoPlayerRef}
                    {...(!autoplay ? getPropsWhenAutoplayOff() : {})}
                    {...(followProgress ? getPropsWhenFollowProgressOn() : {})}
                    playing={playing}
                    url={videoUrl}
                    onEnded={handleEnded}
                    controls
                    onStart={onStart}
                    onReady={playerReady}
                    className={player}
                    width="100%"
                    height="100%"
                    loop={loop}
                />
            ) : (
                <div className={otherContent}>{children}</div>
            )}
        </div>
    );
};

VideoPlayer.propTypes = {
    videoUrl: PropTypes.string,
    autoplay: PropTypes.bool,
    coverUrl: PropTypes.string,
    watchedMark: (props, propName, componentName) => {
        if (typeof props[propName] !== 'number') {
            return new Error(
                `Invalid prop ${propName} supplied to ${componentName}. Expected number. Validation failed 😭`
            );
        } else if (props[propName] <= 0 || props[propName] > 1) {
            return new Error(
                `Invalid prop ${propName} supplied to ${componentName}. Number must be greater than 0 and less than 1. Validation failed 😭`
            );
        }
    },
    followProgress: PropTypes.bool,
    onWatched: PropTypes.func,
    playNext: PropTypes.func,
    onStart: PropTypes.func,
    onLoopEnd: PropTypes.func,
    wrapperClassName: PropTypes.string,
    loop: PropTypes.bool,
    videoDuration: PropTypes.number,
    remainingReps: PropTypes.number,
};

VideoPlayer.defaultProps = {
    videoUrl: '',
    autoplay: true,
    coverUrl: '',
    watchedMark: 0.5,
    followProgress: false,
    loop: false,
    wrapperClassName: '',
    videoDuration: 0,
    remainingReps: 0,
    onWatched: () => {},
    playNext: () => {},
    onStart: () => {},
    onLoopEnd: () => {},
};

export default VideoPlayer;
