import React from 'react';

import { wrapper, nameWrapper, button, link } from './download-block.module.scss';
import FolderIcon from '../../../assets/images/svg/icon-folder.svg';

import Button from '../../atoms/button';
import ProtectedFile from '../../hoc/protected-file';

const DownloadBlock = ({ file, className }) => {
    const { name, uri, type } = file;

    return (
        <div className={`${wrapper} ${className}`}>
            <FolderIcon />
            <div className={nameWrapper}>{name}</div>
            {uri && (
                <ProtectedFile url={uri} className={link} mimeType={type} isDownload={true}>
                    <Button className={button} size="small">
                        Pobierz
                    </Button>
                </ProtectedFile>
            )}
        </div>
    );
};

export default DownloadBlock;
