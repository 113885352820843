import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    wrapper,
    asideList,
    emptyScreen,
    video,
    fakeWrapperIconLock,
    iconLock,
    pointsButtonWrapper,
    ratioClass,
    notBoughtButtons,
} from './courses-movie-group.module.scss';
import IconLock from '../../../assets/images/svg/icon-lock.svg';
import gamificationReasons from '../../../global-enums/gamification-reasons.json';
import { selectCourseCurrentMovie } from '../../../redux/current-course/selectors/course-movies-list.selectors';
import useDimensions from '../../../hooks/use-dimensions';
import { selectCartIsLoading } from '../../../redux/shop/cart/cart.selectors';

import CourseMoviesList from '../course-movies-list/course-movies-list';
import VideoPlayer from '../video-player';
import GetPointsButton from '../../organisms/get-points-button';
import GetPointsButtonWrapper from '../get-points-button-wrapper';
import RatioImage from '../../atoms/ratio-image';
import Button from '../../atoms/button';

const CoursesMovieGroup = ({
    className = '',
    playNext,
    courseData,
    onAddToCart,
    onSeeMore,
    onRestrictedMovie,
}) => {
    const { price, isBought, canBuy } = courseData;
    const currentMovie = useSelector(selectCourseCurrentMovie);
    const [isMovieChanged, setIsMovieChanged] = useState(false);

    const cartLoading = useSelector(selectCartIsLoading);
    const movieBoxRef = useRef(null);
    const [, movieBoxHeight] = useDimensions(movieBoxRef);
    const [player, setPlayer] = useState(null);

    const handleChangeMovie = () => {
        player?.getInternalPlayer()?.play();
        setIsMovieChanged(true);
    };

    const handleReady = (player) => {
        setPlayer(player);
        if (isMovieChanged) {
            player?.getInternalPlayer()?.play();
        }
    };

    return (
        <div className={className}>
            <div className={wrapper}>
                <section>
                    <div ref={movieBoxRef}>
                        <VideoPlayer
                            videoUrl={currentMovie.uri}
                            playNext={playNext}
                            autoplay={false}
                            followProgress={true}
                            wrapperClassName={video}
                            onReady={handleReady}
                        >
                            <button className={emptyScreen} onClick={onRestrictedMovie}>
                                <RatioImage
                                    src={currentMovie.coverViewUri}
                                    ratioClass={ratioClass}
                                />
                                <IconLock className={iconLock} />
                                <div className={fakeWrapperIconLock} />
                            </button>
                        </VideoPlayer>
                    </div>
                </section>
                <section className={asideList} style={{ height: `${movieBoxHeight || 400}px` }}>
                    <CourseMoviesList
                        onRestrictedMovie={onRestrictedMovie}
                        onChangeMovie={handleChangeMovie}
                    />
                </section>
                {isBought && (
                    <GetPointsButtonWrapper className={pointsButtonWrapper}>
                        <GetPointsButton
                            reasonId={gamificationReasons.courseMovieWatched}
                            objectId={currentMovie.movieId}
                        >
                            Ukończyłem lekcję
                        </GetPointsButton>
                    </GetPointsButtonWrapper>
                )}
                {!isBought && canBuy && (
                    <div className={notBoughtButtons}>
                        <Button color="yellow" onClick={onAddToCart} disabled={cartLoading}>
                            Kup ten kurs za {price.grossDisplay}
                        </Button>
                        <Button color="blank" onClick={onSeeMore}>
                            Dowiedz się więcej o kursie
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CoursesMovieGroup;
