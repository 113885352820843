// extracted by mini-css-extract-plugin
export var check = "transition-day-button-module--check--o4QHa";
export var close = "transition-day-button-module--close--AsAtf";
export var container = "transition-day-button-module--container--5ayag";
export var dayNumber = "transition-day-button-module--day-number--1IKhT";
export var dayText = "transition-day-button-module--day-text--5wljt";
export var done = "transition-day-button-module--done---N29K";
export var iconBox = "transition-day-button-module--icon-box--teJpF";
export var partial = "transition-day-button-module--partial--MTfb-";
export var plus = "transition-day-button-module--plus--jkSGA";
export var preview = "transition-day-button-module--preview--UcM9M";
export var today = "transition-day-button-module--today--ZFqsZ";